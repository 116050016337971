import { useOutletContext, useParams } from 'react-router-dom';
import React, { FC, useCallback, useEffect, useMemo } from 'react';

import { ActionName } from 'context/CurrentUserContext.types';
import { NO_PERMISSION_MESSAGE } from 'constants/constants';
import { SelectFieldOption } from 'components/core/Form/SelectField/SelectField.types';
import { SendMoneyOutletContext } from 'components/views/app/organization/SendMoney/SendMoney.types';
import { TransactionPurpose } from 'store/api/api.types';
import { isFiat } from 'utils/format';
import { useCreateTransactionContext } from 'context/CreateTransactionContext';
import { useCurrentUser } from 'context/CurrentUserContext';
import Button from 'components/core/Button/Button';
import FileDropzone from 'components/core/Form/FileDropzone/FileDropzone';
import InputField from 'components/core/Form/InputField/InputField';
import SelectField from 'components/core/Form/SelectField/SelectField';
import Text from 'components/core/Text/Text';
import Tooltip from 'components/core/Tooltip/Tooltip';

import { LocalQuoteFormErrors, QuoteFormProps } from './QuoteForm.types';
import { transactionSelectOptions } from './QuoteForm.constants';
import styles from './QuoteForm.module.scss';

import { getLiquidityLimitStatus } from '../LiquidityLimitMessage/LiquidityLimitMessage.utils';
import AssetsSelector from '../AssetsSelector/AssetsSelector';
import BankSelect from '../BankSelect/BankSelect';
import CutOffMessage from '../CutOffMessage/CutOffMessage';
import HolidayMessage from '../HolidayMessage/HolidayMessage';
import LiquidityLimitMessage from '../LiquidityLimitMessage/LiquidityLimitMessage';
import OperatingHoursMessage from '../OperatingHoursMessage/OperatingHoursMessage';

const QuoteForm: FC<QuoteFormProps> = ({ onContinue }) => {
  const { bankAccounts, liquidityLimits } = useOutletContext<SendMoneyOutletContext>();
  const {
    isQuoteInProgress,
    isSourceAmountValid,
    isTargetAmountValid,
    quote,
    quoteStatusLabel,
    recipient,
    sender,
    setIsSourceAmountValid,
    setIsTargetAmountValid,
    setRecipient,
    setSender,
    setSupportingDocumentFile,
    setTransactionPurpose,
    reference,
    setReference,
    sourceAssetType,
    supportingDocumentFile,
    targetAssetType,
    transactionPurpose,
    sourceAmount,
    targetAmount,
    isQuoteFormDisabled,
  } = useCreateTransactionContext();

  const { organizationId } = useParams() as { organizationId: string };
  const { isUserAllowedTo } = useCurrentUser();

  const [localFormErrors, setLocalFormErrors] = React.useState<LocalQuoteFormErrors>({});

  const setFormError = useCallback((field: keyof LocalQuoteFormErrors, error?: string) => {
    setLocalFormErrors(prev => ({ ...prev, [field]: error }));
  }, []);

  const hasFormErrors = (formErrors: LocalQuoteFormErrors) =>
    !!Object.values(formErrors).find(error => !!error);

  const onAddSupportingDocumentFile = (files: File[]) => {
    setFormError('supportingDocumentFile', undefined);
    setSupportingDocumentFile(files[0]);
  };

  const onRemoveSupportingDocumentFile = () => {
    setSupportingDocumentFile(undefined);
  };

  const isFiatWithdrawal = useMemo(() => isFiat(targetAssetType?.id), [targetAssetType]);

  useEffect(() => {
    if (!isFiatWithdrawal) {
      setTransactionPurpose(undefined);
      setSupportingDocumentFile(undefined);
      setReference('');
    }
  }, [isFiatWithdrawal, setSupportingDocumentFile, setTransactionPurpose, setReference]);

  const handleOnContinue = () => {
    const formErrors = {} as LocalQuoteFormErrors;
    if (isFiat(sourceAssetType) && !sender?.value) {
      formErrors.sender = 'Please select a sender.';
    }
    if (isFiat(targetAssetType) && !recipient?.value) {
      formErrors.recipient = 'Please select a recipient.';
    }
    if (isFiatWithdrawal) {
      if (!supportingDocumentFile) {
        formErrors.supportingDocumentFile = 'Required for payment processing.';
      }
      if (!transactionPurpose) {
        formErrors.transactionPurpose = 'Please select a transaction purpose.';
      }
    }
    if (hasFormErrors(formErrors)) {
      setLocalFormErrors(formErrors);
      return;
    }
    onContinue();
  };

  const canCreateTransaction = useMemo(() => {
    return isUserAllowedTo(organizationId, ActionName.CreateTransaction);
  }, [organizationId, isUserAllowedTo]);

  const liquidityLimitStatus = useMemo(
    () => getLiquidityLimitStatus(liquidityLimits, targetAmount, targetAssetType),
    [liquidityLimits, targetAmount, targetAssetType],
  );

  const cannotSubmitTransaction = useMemo(() => {
    return (
      !canCreateTransaction ||
      isQuoteInProgress ||
      !quote ||
      liquidityLimitStatus.isOverLimit ||
      !isSourceAmountValid ||
      !isTargetAmountValid ||
      !sourceAmount ||
      !targetAmount
    );
  }, [
    canCreateTransaction,
    isQuoteInProgress,
    quote,
    liquidityLimitStatus,
    isSourceAmountValid,
    isTargetAmountValid,
    sourceAmount,
    targetAmount,
  ]);

  const handleOnTransactionPurposeSelect = useCallback(
    (option: SelectFieldOption | null) => {
      setTransactionPurpose(option?.value as TransactionPurpose);
    },
    [setTransactionPurpose],
  );

  const handleOnSenderSelect = useCallback(
    (option: SelectFieldOption) => {
      setSender(option);
      setFormError('sender', undefined);
    },
    [setSender, setFormError],
  );

  const handleOnRecipientSelect = useCallback(
    (option: SelectFieldOption) => {
      setRecipient(option);
      setFormError('recipient', undefined);
    },
    [setRecipient, setFormError],
  );

  return (
    <>
      <Text align='center' variant='sectionHeaderLarge'>
        Send Money{canCreateTransaction ? '' : ' - Quote Preview'}
      </Text>
      <LiquidityLimitMessage liquidityLimitStatus={liquidityLimitStatus} />
      <HolidayMessage sourceAssetType={sourceAssetType} targetAssetType={targetAssetType} />
      <OperatingHoursMessage assetType={sourceAssetType} />
      <OperatingHoursMessage assetType={targetAssetType} />
      <AssetsSelector
        setIsSourceAmountValid={setIsSourceAmountValid}
        setIsTargetAmountValid={setIsTargetAmountValid}
      />
      <BankSelect
        assetType={sourceAssetType}
        bankAccounts={bankAccounts}
        bankId={sender?.value}
        bankType='sender'
        error={localFormErrors?.sender}
        isDisabled={isQuoteFormDisabled}
        onSelect={handleOnSenderSelect}
      />
      <BankSelect
        assetType={targetAssetType}
        bankAccounts={bankAccounts}
        bankId={recipient?.value}
        bankType='recipient'
        error={localFormErrors?.recipient}
        isDisabled={isQuoteFormDisabled}
        onSelect={handleOnRecipientSelect}
      />
      {isFiatWithdrawal && (
        <>
          <SelectField
            error={localFormErrors?.transactionPurpose}
            isDisabled={isQuoteFormDisabled}
            isRequired
            label='Purpose'
            onChange={() => setFormError('transactionPurpose', undefined)}
            onSelect={handleOnTransactionPurposeSelect}
            options={transactionSelectOptions}
            value={transactionSelectOptions.find(option => option.value === transactionPurpose)}
          />
          <FileDropzone
            description='Proper payment purpose selection and document submission are mandatory for Conduit to process transactions. Our banking partners require documentation to verify payment purposes for all B2B payments.'
            error={localFormErrors?.supportingDocumentFile}
            fileRequirements={{
              maxSize: 12 * 1024 * 1024,
              mimeTypes: ['PDF', 'JPG', 'XLSX', 'DOCX'],
            }}
            files={supportingDocumentFile ? [supportingDocumentFile] : []}
            isDisabled={isQuoteFormDisabled}
            onAddFile={onAddSupportingDocumentFile}
            onRemoveFile={onRemoveSupportingDocumentFile}
            title='Provide Supporting Documents'
          />
          <InputField
            isDisabled={isQuoteFormDisabled}
            label='Reference (Optional)'
            onChange={({ target: { value } }) => setReference(value)}
            value={reference}
          />
        </>
      )}
      <CutOffMessage sourceAssetType={sourceAssetType} targetAssetType={targetAssetType} />
      <Text align='center' marginBottom={5} marginTop={5} variant='bodyCopySmall'>
        {quoteStatusLabel}
      </Text>
      <Tooltip isHidden={canCreateTransaction} label={NO_PERMISSION_MESSAGE}>
        <Button
          className={styles.continueButton}
          isDisabled={isQuoteFormDisabled || cannotSubmitTransaction}
          label='Continue'
          onClick={handleOnContinue}
          type='submit'
          variant='primary'
        />
      </Tooltip>
    </>
  );
};

export default QuoteForm;
