import React, { FC } from 'react';

import Noticebox from 'components/core/Noticebox/Noticebox';
import Text from 'components/core/Text/Text';

import { LiquidityLimitProps } from './LiquidityLimitMessage.types';

const LiquidityLimitMessage: FC<LiquidityLimitProps> = ({ liquidityLimitStatus }) => {
  if (liquidityLimitStatus.isOverLimit) {
    return (
      <Noticebox marginTop={4} variant='warning'>
        <Text>
          <b>{liquidityLimitStatus.message?.primary}</b>
          {liquidityLimitStatus.message?.secondary}
        </Text>
      </Noticebox>
    );
  }

  return null;
};

export default LiquidityLimitMessage;
