import { Outlet, useParams } from 'react-router-dom';
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import unionBy from 'lodash/unionBy';

import { Account, ApiParams, BankAccount } from 'store/api/api.types';
import { AssetType } from 'constants/assetTypes.types';
import { SEND_MONEY_CURRENCY_TYPES } from 'constants/assetTypes';
import { StablecoinHeader } from 'components/dedicated/organization/send-money';
import { getTradeablePairs } from 'components/dedicated/organization/send-money/AssetsSelector/AssetsSelector.utils';
import {
  useGetEnvironmentAccountQuery,
  useGetLiquidityLimitsQuery,
  useGetTradingPairsQuery,
  useGetWalletsQuery,
  useLazyGetBankAccountsQuery,
  useLazyGetIdentitiesQuery,
} from 'store/api/platformApi';
import ContentSection from 'components/core/ContentSection/ContentSection';
import FrameBox from 'components/core/FrameBox/FrameBox';
import Spinner from 'components/core/Spinner/Spinner';
import Text from 'components/core/Text/Text';

import { SendMoneyOutletContext } from './SendMoney.types';

let refetchQueryTimeout: NodeJS.Timeout | number;
const QUERY_REFETCH_INTERVAL = 5000;
const GET_IDENTITIES_PAGE_SIZE = 100;
const GET_BANK_ACCOUNTS_PAGE_SIZE = 100;

const SendMoney = (): ReactElement => {
  const { organizationId } = useParams() as { organizationId: string };
  const refetchQueryTimeoutRef = useRef(refetchQueryTimeout);

  const [isSanctioned, setIsSanctioned] = useState<boolean>(false);
  const [assetTypes, setAssetTypes] = useState<AssetType[]>();
  const [account, setAccount] = useState<Account>();

  const { isSuccess: isGetWalletsSuccessful, data: walletsData } = useGetWalletsQuery({
    organizationId,
  });
  const {
    isSuccess: isGetEnvironmentAccountSuccessful,
    data: environmentAccountData,
    refetch: refetchGetEnvironmentAccountQuery,
    fulfilledTimeStamp: fulfilledTimeStampGetEnvironmentAccount,
  } = useGetEnvironmentAccountQuery({ organizationId });

  const { data: tradingPairsData } = useGetTradingPairsQuery({ organizationId });
  const tradingPairs = useMemo(
    () => getTradeablePairs(tradingPairsData?.data || []),
    [tradingPairsData],
  );

  const { data: liquidityLimits } = useGetLiquidityLimitsQuery({ organizationId });

  const [bankAccountList, setBankAccountList] = useState<BankAccount[]>([]);
  const [getBankAccounts] = useLazyGetBankAccountsQuery();

  const nextGetBankAccounts = (params: ApiParams) => {
    getBankAccounts({ organizationId, params }).then(results => {
      if (results.data) {
        const { data: resultsData } = results;
        if (resultsData?.data && resultsData.data.length > 0) {
          setBankAccountList(previous => unionBy(previous, resultsData.data, 'id'));

          if (resultsData?.links?.next) {
            nextGetBankAccounts({
              page: { after: resultsData.links.next, size: GET_BANK_ACCOUNTS_PAGE_SIZE },
            });
          }
        }
      }
    });
  };

  const [identityList, setIdentityList] = useState<string[]>([]);
  const [getIdentities] = useLazyGetIdentitiesQuery();

  const nextGetIdentities = (params: ApiParams) => {
    getIdentities({ organizationId, params }).then(results => {
      if (results.data) {
        const resultsData = results.data;
        if (resultsData?.data) {
          const list = resultsData.data
            .filter(identity => identity.verificationStatus === 'approved')
            .map(identity => identity.id);

          if (list && list.length > 0) {
            setIdentityList(previous => previous.concat(list as string[]));
          }

          if (resultsData?.links?.next) {
            nextGetIdentities({
              page: { after: resultsData.links.next, size: GET_IDENTITIES_PAGE_SIZE },
            });
          }
        }
      }
    });
  };

  useEffect(() => {
    nextGetIdentities({ page: { size: GET_IDENTITIES_PAGE_SIZE } });
    nextGetBankAccounts({ page: { size: GET_BANK_ACCOUNTS_PAGE_SIZE } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isGetWalletsSuccessful && isGetEnvironmentAccountSuccessful) {
      const types: AssetType[] = structuredClone(SEND_MONEY_CURRENCY_TYPES);

      setIsSanctioned(environmentAccountData.data.attributes.status !== 'approved');

      // Get Asset Type Balances and Deposit Address
      const updatedTypes = types.map(type => {
        const allocation = environmentAccountData.included.find(
          included =>
            included.type === 'allocation' &&
            included.attributes.poolName?.includes('default_pool_') &&
            included.attributes.balances?.available[0].assetType === type.id,
        );
        const availableBalances = environmentAccountData.data.attributes.balances?.available || {};
        const balance =
          availableBalances.find(availableBalance => availableBalance.assetType === type.id)
            ?.amount || '0';
        const custodyWallet = walletsData.data.find(
          wallet => wallet.attributes.assetType === type.id,
        );
        return {
          ...type,
          allocationId: allocation?.id,
          balance,
          depositAddress: custodyWallet ? custodyWallet.attributes.address : type.depositAddress,
          max: BigInt(balance || 0),
        };
      });

      const updatedAccount = environmentAccountData;

      if (!isEqual(updatedTypes, assetTypes)) {
        setAssetTypes(updatedTypes);
      }

      if (!isEqual(updatedAccount, account)) {
        setAccount(updatedAccount);
      }
    }
  }, [
    account,
    assetTypes,
    environmentAccountData,
    isGetEnvironmentAccountSuccessful,
    isGetWalletsSuccessful,
    refetchGetEnvironmentAccountQuery,
    walletsData,
  ]);

  useEffect(() => {
    clearTimeout(refetchQueryTimeoutRef.current as NodeJS.Timeout);
    refetchQueryTimeoutRef.current = setTimeout(() => {
      refetchGetEnvironmentAccountQuery();
    }, QUERY_REFETCH_INTERVAL);
    return () => {
      clearTimeout(refetchQueryTimeoutRef.current as NodeJS.Timeout);
    };
  }, [fulfilledTimeStampGetEnvironmentAccount, refetchGetEnvironmentAccountQuery]);

  const bankAccounts = useMemo(() => {
    if (bankAccountList && identityList) {
      return bankAccountList.filter(bank => {
        const identityId = bank.relationships.identities.data[0].id;
        return bank.attributes.isEnabledToReceiveFunds && identityList.includes(identityId);
      });
    }
  }, [bankAccountList, identityList]);

  const outletContext: SendMoneyOutletContext = useMemo(
    () => ({
      accountId: account?.data.id,
      assetTypes: assetTypes || [],
      bankAccounts: bankAccounts || [],
      liquidityLimits: liquidityLimits || [],
      tradingPairs,
    }),
    [account, assetTypes, bankAccounts, liquidityLimits, tradingPairs],
  );

  if (isSanctioned) {
    return (
      <ContentSection>
        <Text align='center' marginTop={5}>
          You&apos;re unable to access the Send Money feature due to compliance reasons. Please{' '}
          <a href='https://www.conduit.financial/contact' rel='noreferrer' target='_blank'>
            contact
          </a>
          <br />
          our support team for further assistance.
        </Text>
      </ContentSection>
    );
  }

  return (
    <ContentSection>
      <StablecoinHeader assetTypes={assetTypes} organizationId={organizationId} />
      <FrameBox>
        {!account || !tradingPairsData || !liquidityLimits ? (
          <Spinner />
        ) : (
          <Outlet context={outletContext} />
        )}
      </FrameBox>
    </ContentSection>
  );
};

export default SendMoney;
